<template>
  <v-dialog
    v-model="dialog"
    width="500"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="rounded-lg"
        v-if="['shibui'].includes(currentUser.currentRoleName)"
        depressed
        color="error"
        @click=""
        v-bind="attrs"
        v-on="on"
        >
        Eliminar
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="justify-center">
        Eliminar dashboard
      </v-card-title>

      <v-card-text>
        ¿Está seguro de que desea eliminar el dashboard {{ dashboard.name }}? Esta acción <span class="error--text font-weight-bold">no se puede deshacer</span>.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          :loading="loading"
          color="error"
          text
          @click="deleteDashboard"
          >
          Eliminar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { DeleteDashboard } from '@/graphql/mutations/dashboards'
import { Roles } from '@/utils/roles'

export default {
  data: () => ({
    roles: Roles,
    dialog: false,
    loading: false,
  }),

  props: {
    dashboard: {
      type: Object,
      required: true,
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    deleteDashboard () {
      this.loading = true
      this.$apollo.mutate({
        mutation: DeleteDashboard,
        variables: {
          input: {
            id: this.dashboard.id
          }
        }
      }).then ( res => {
        this.$router.go()
      })
    }
  }
}
</script>
