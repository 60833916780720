import gql from 'graphql-tag'

export const EditDashboard = gql`
  mutation editDashboard($input: EditDashboardInput!) {
    editDashboard(input: $input) {
      success
    }
  }
`

export const CreateDashboard = gql`
  mutation createDashboard($input: CreateDashboardInput!) {
    createDashboard(input: $input) {
      success
    }
  }
`

export const DeleteDashboard = gql`
  mutation deleteDashboard($input: DeleteDashboardInput!) {
    deleteDashboard(input: $input) {
      success
    }
  }
`
